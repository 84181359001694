import React from "react";
import "./Contact.css";

export const Contact = () => {
  return (
    <div>
        <br></br>
        <div class="contactus">
            <h1 style={{ color: "#ffffff" }}>Contact Us</h1>
            <p>We are here to help you with any questions or concerns you may have. There are several ways to get in touch with us:</p>
            <p><strong>Discord:</strong> Join our Discord community for quick and easy support. You can connect with us and other customers, ask questions, and get help with any issues you may be experiencing. Click here to join our Discord server: <a href="https://discord.sodiumhosting.com" target="_blank">https://discord.sodiumhosting.com</a></p>
            <p><strong>Email:</strong> If you prefer to send an email, you can reach us at <a href="mailto:contact@sodiumhosting.com">contact@sodiumhosting.com</a>. We typically respond within 24 hours.</p>
            <p><strong>Tickets:</strong> If you need technical assistance or have an issue that requires our attention, please submit a support ticket at <a href="https://billing.sodiumhosting.com/tickets" target="_blank">https://billing.sodiumhosting.com/tickets</a>. We'll respond as quickly as possible.</p>
            <p>We're committed to providing excellent customer service, and we're always here to help you. Don't hesitate to reach out if you need assistance.</p>
        </div>
        <br></br>
    </div>
  );
};
