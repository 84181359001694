import React, { useState, useEffect }from "react";
import "./Partner.css";

export const Partner = () => {
  const [partnersDetails, setPartnersDetails] = useState([]);
  useEffect(() => {
    fetch('/partners.json')
      .then(res => res.json())
      .then(data => setPartnersDetails(data))
      .catch(error => console.error(error));
  }, []);
  return (
    <div>
      <br></br>
        <div class="partner">
          <h1 style={{ color: "#ffffff" }}>Become a SodiumHosting Partner</h1>
          <p>Are you ready to start earning passive income?</p>
          <p>Join our SodiumHosting Partner Program and receive a unique link that you can share with potential customers.</p>
          <p>Spread the word about our premium services on all social media platforms and watch the commissions roll in!</p>
          <br></br>
          <p>Each time someone purchases a service using your link, you'll receive a 20% commission.</p>
          <p>And the best part? This applies to all future recurring purchases.</p>
          <p>You'll earn credits that can be used to pay for your own servers or withdrawn directly to your payment account.</p>
          <p>Imagine someone using your link to buy a service, which costs $20. You'll receive a commission of $4!</p>
          <p>And as long as that customer continues to use our services, you'll continue to earn 20% commission on every purchase they make.</p>
          <br></br>
          <p>Looking for a personalized touch to your referral link?</p>
          <p>We've got you covered. Simply reach out to us, and we'll create a custom link like this: <a href="https://sodiumhosting.com/partnerships">https://sodiumhosting.com/your-link</a>, just for you.</p>
          <p>Our team is here to help you succeed, so don't hesitate to contact us with any questions or requests.</p>
          <p>Join the SodiumHosting Partner Program today and start earning passive income with your own unique link!</p>
          <br></br>
          <a href="/contact" className="contact-button">Contact Us</a>
          <br></br>
          <br></br>
          <br></br>
          <h1 style={{ color: "#ffffff", fontsize: "30" }}>Our partners</h1>
          <div className="d-flex row justify-content-center">
            {partnersDetails.map(partnersDetail => (
	    <div class="partnerbox">
              <img src={partnersDetail.imageurl} alt={partnersDetail.name} />
              <div>
                <a href={partnersDetail.external}>{partnersDetail.name}</a>
                <p>{partnersDetail.description}</p>
              </div>
            </div>
            ))}
          </div>
        </div>
      <br></br> 
    </div>
  );
};