import React from 'react';
import "./NavBar.css";
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBar() {
  return (
    <>
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container>
        <span style={{ display: 'inline-block', alignItems: 'center' }}>
          <a href="/">
            <img src="/img/Logo2.png" alt="Logo" style={{ height: '50px', marginRight: '10px' }} /><img/>
          </a>
          {//<Navbar.Brand href="/" style={{ fontWeight: "bold" }}>SodiumHosting</Navbar.Brand>
          }
        </span>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link href="minecraft">Minecraft</Nav.Link>
            <NavDropdown title="Games" id="collasible-nav-dropdown">
              <NavDropdown.Item href="services">All Services</NavDropdown.Item>
              <NavDropdown.Item href="minecraft">Minecraft</NavDropdown.Item>
              <NavDropdown.Item href="unturned">Unturned</NavDropdown.Item>
              {/*<NavDropdown.Item href="factorio">Factorio</NavDropdown.Item>
              <NavDropdown.Item href="zomboid">Project Zomboid</NavDropdown.Item>*/}
            </NavDropdown>
            <Nav.Link href="vps">VPS Servers</Nav.Link>
            <NavDropdown title="Log In" id="collasible-nav-dropdown">
              <NavDropdown.Item href="https://billing.sodiumhosting.com">Billing</NavDropdown.Item>
              <NavDropdown.Item href="https://panel.sodiumhosting.com">Game Panel</NavDropdown.Item>
              <NavDropdown.Item href="https://vps.sodiumhosting.com">VPS Panel</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default NavBar;
