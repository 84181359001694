import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import "./App.css";
import { Home } from './components/Pages/Home';
import { About } from './components/Pages/About';
import { Contact } from './components/Pages/Contact';
import { ErrorPage } from './components/Pages/ErrorPage';
import { Minecraft } from './components/Pages/Minecraft';
import { Unturned } from './components/Pages/Unturned';
import { Factorio } from './components/Pages/Factorio';
import { Services } from './components/Pages/Services';
import { TOS } from './components/Pages/TOS';
import { VPS } from './components/Pages/VPS';
import { Zomboid } from './components/Pages/Zomboid';
import { Partner } from './components/Pages/Partner';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
       {/* <Route path="/about" element={<About />} />*/}
        <Route path="/contact" element={<Contact />} />
        <Route path="/minecraft" element={<Minecraft />} />
        <Route path="/unturned" element={<Unturned />} />
        <Route path="/services" element={<Services />} />
        {/*<Route path="/factorio" element={<Factorio />} /> */}
        <Route path="/tos" element={<TOS />} />
       {/*  <Route path="/zomboid" element={<Zomboid />} /> */}
        <Route path="/partnerships" element={<Partner />} />
        <Route path="/vps" element={<VPS />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
