import React, { useEffect, useState, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ErrorPage = () => {
  const [partnersData, setPartnersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    async function getPartnersData() {
      try {
        const response = await fetch('/partners.json', { cache: 'no-store' });
        if (response.ok) {
          const data = await response.json();
          setPartnersData(data);
          setIsLoading(false); // Set loading to false after data is fetched
        } else {
          throw new Error("Failed to fetch partners data");
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false); // Also set loading to false if there's an error
      }
    }
    getPartnersData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Display loading state
  }


  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <PartnersRoutes partnersData={partnersData} />
      </Suspense>
      <h1>404 Not found!</h1>
    </div>
  );
};

const PartnersRoutes = ({ partnersData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function setPartnerCookieAndRedirect() {
      const currentPath = location.pathname.replace('/', '');
      const partner = Object.values(partnersData).find((p) => p.link === currentPath);
      const partnerId = partner ? partner.affiliate : null;

      if (partnerId) {
        document.cookie = `partnerAffiliateID=${partnerId}; domain=sodiumhosting.com; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
        console.log("Partner Affiliate Cookie Set", partnerId);
        navigate('/'); // Redirect after setting cookie
      }
    }

    setPartnerCookieAndRedirect();
  }, [partnersData, location.pathname, navigate]);

  return null; // This component does not render UI
};
