import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <footer>
        <div className="row primary">

        <div className="column links">
        <h3>Quick Links</h3>
         <ul>
          <li>
           <a href="contact">Support</a>
          </li>
          <li>
           <a href="contact">Contact</a>
          </li>
          <li>
           <a href="https://status.sodiumhosting.com/">Status</a>
          </li>
         </ul>

        </div>


        <div className="column links">
          <h3>Navigate</h3>
           <ul>
            {/*<li>
             <a href="about">About Us</a>
  </li>*/}
            <li>
            <a href="tos">Terms Of Service & Privacy Policy</a>
            </li>
            <li>
            <a href="partnerships">Partners</a>
            </li>
          </ul>
        </div>
        </div>

        <div className="copyright">
           <p>Copyright 2024 © SodiumHosting. All rights reserved.</p>
        </div>
    </footer>
  );
};
export default Footer;