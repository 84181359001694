import React, { useState, useEffect }from "react";
import "./Minecraft.css";
import "./public.css";
import 'bootstrap/dist/css/bootstrap.css';

export const Minecraft = () => {
  const [packageDetails, setPackageDetails] = useState([]);
  const [affiliateID, setAffiliateID] = useState(null);
  useEffect(() => {
    fetch('/minecraft.json')
      .then(res => res.json())
      .then(data => setPackageDetails(data))
      .catch(error => console.error(error));
      const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      
      const affiliateIDFromCookie = getCookie('partnerAffiliateID');
      if (affiliateIDFromCookie) {
        setAffiliateID(affiliateIDFromCookie);
      }
  }, []);
  return (
    <div className="info">
      <br></br>
      <h1>Minecraft Java</h1>
      <div class="game-cont">
        <div className="d-flex row justify-content-center">
          {packageDetails.map(packageDetail => (
            <div class={`packagebox ${packageDetail.id}`}>
              <h2 style={{ color: "#ffffff" }}>{packageDetail.name}</h2>
              <p>{packageDetail.memory/1024} GB RAM</p>
              <p>{packageDetail.cpu/100} {packageDetail.cpu/100 === 1 ? 'vCore' : 'vCores'}</p>
              <p>Pterodactyl Panel</p>
              <p>Starting At ${Number(packageDetail.price).toFixed(2).replace(/\.?0+$/,"")}</p>
              <a href={`${packageDetail.link}${affiliateID ? `?ref=${encodeURIComponent(affiliateID)}` : ''}`}>Order Now</a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};