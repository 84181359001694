import React from "react";

export const TOS = () => {
  return (
    <div>
      <iframe
        src={`https://docs.google.com/document/d/e/2PACX-1vQ3UFWna3VB_Ugys45uULO_QZ1yPMLPaVJ6gurE9k1Ee45IbAlSiHq46Eu519hFSfqmtHrwyrgEXSu7/pub`}
        width="100%"
        height="1000px"
      />
    </div>
  );
};
