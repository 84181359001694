import React, { useState, useEffect }from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "./Services.css";

export const Services = () => {
    const [servicesDetails, setServicesDetails] = useState([]);
    useEffect(() => {
      fetch('/services.json')
        .then(res => res.json())
        .then(data => setServicesDetails(data))
        .catch(error => console.error(error));
    }, []);
  return (
    <div>
      <br></br>
      <br></br>
      <h1 style={{ color: "#ffffff" }}>All Services</h1>
      <h1 style={{ fontSize:"1.5rem", color: "#ffffff" }}>Here you can find a list of all services we offer.</h1>
      <div class="Services-parent-container">
        <div className="d-flex flex-row justify-content-center flex-wrap">
          {servicesDetails.map(servicesDetail => (
            <div class="servicescontainer">
              <div class="servicesbox">
                <img src={`${servicesDetail.image}`}/>
                <div class="servicestext">
                  <h3 style={{ fontWeight: "bold" }}>{servicesDetail.name}</h3>
                  <r5>{servicesDetail.desc}</r5>
                </div>
                <a href={`${servicesDetail.link}`}>Order Now</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
