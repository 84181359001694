import React, { useState, useEffect } from "react";
import "./VPS.css";
import "./public.css";
import 'bootstrap/dist/css/bootstrap.css';

export const VPS = () => {
  const [packageDetails, setPackageDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [closing, setClosing] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0);
  const [affiliateID, setAffiliateID] = useState(null);

  useEffect(() => {
    fetch('/vps.json')
      .then(res => res.json())
      .then(data => setPackageDetails(data))
      .catch(error => console.error(error));
      const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      
      const affiliateIDFromCookie = getCookie('partnerAffiliateID');
      if (affiliateIDFromCookie) {
        setAffiliateID(affiliateIDFromCookie);
      }
  }, []);

  const togglePopup = () => {
    if (showPopup) {
      setClosing(true);
      setTimeout(() => {
        setShowPopup(false);
        setClosing(false);
      }, 900); // Adjust the duration of the animation (in milliseconds) to match the CSS animation duration
    } else {
      setShowPopup(true);
    }
  };

  const isMobile = window.innerWidth <= 767;

  const buttonData = [
    { title: "Xeon CPUs", description: "Our Xeon CPUs paired with DDR4 offer exceptional multitasking prowess, rock-solid reliability, and advanced security features. This combination makes them the ultimate choice for high-performance computing, delivering lightning-fast data processing and efficient memory management. With DDR4 and Xeon CPUs we are ensuring seamless performance and uncompromising reliability in demanding workloads." },
    { title: "Uptime", description: "Here at SodiumHosting we prioritize uptime. Offering our customers an exceptional hosting experience with a 99.9% service availability. Trust us for reliable servers." },
    { title: "Easy Management", description: "Discover the simplicity of VPS management with our user-friendly control panel. Effortlessly take charge of your virtual private server and optimize its performance. Stay informed with real-time monitoring. Upgrade your hosting experience now and unlock the full potential of your VPS." },
  ];

  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  return (
    <div className="info">
      <br></br>
      <h1>VPS Servers</h1>
      <div className="game-cont">
        <div className={`half-screen ${showPopup ? "open" : ""}`}>
          <img src="/img/virt.png" alt="Virt" onClick={!isMobile ? togglePopup : undefined} />
          <div className={`text-container ${showPopup ? "open" : ""}`}>
            <h2>Maximize Efficiency with Our Control Panel</h2>
            <p>Upgrade your server resources with our VPSes. Enjoy full root access to your server. Powered by high-performance Intel Xeon CPUs with DDR4 and lightning-fast SSD drives, our top-of-the-line service guarantees impressive results.</p>
          </div>
        </div>
        <div className="d-flex row justify-content-center">
          {packageDetails.map(packageDetail => (
            <div className={`packagebox ${packageDetail.id}`} key={packageDetail.id}>
            <h2>{packageDetail.name}</h2>
            {packageDetail.name.includes('BYO') ? (
              <React.Fragment>
                <p>Nothing fits your needs?.</p>
                <p>Want something custom?</p>
                <p>Feel free to contact us.</p>
                <p>We can customize to your needs.</p>
                <p>Press below to contact.</p>
                <a href={`${packageDetail.link}`}>Contact Us</a>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>{packageDetail.memory / 1024} GB RAM</p>
                <p>{packageDetail.cpu / 100} {packageDetail.cpu / 100 === 1 ? 'vCore' : 'vCores'}</p>
                <p>{packageDetail.storage} Storage</p>
                <p>{packageDetail.bandwidth} Bandwidth</p>
                <p>Virtfusion Panel</p>
                <p>Starting At ${Number(packageDetail.price).toFixed(2).replace(/\.?0+$/, "")}</p>
                <a href={`${packageDetail.link}${affiliateID ? `?ref=${encodeURIComponent(affiliateID)}` : ''}`}>Order Now</a>
              </React.Fragment>
            )}
            </div>
          ))}
        </div>
      <h6>Why choose SodiumHosting's VPS Servers?</h6>
        <div className="d-flex row justify-content-start">
          <div className="half-screen">
            <div className="button-container">
              {buttonData.map((button, index) => (
                <button
                  key={index}
                  className={selectedButton === index ? "active" : ""}
                  onClick={() => handleButtonClick(index)}
                >
                  {button.title}
                </button>
              ))}
            </div>
            <div className="text-container">
              <h2>{buttonData[selectedButton].title}</h2>
              <p>{buttonData[selectedButton].description}</p>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className={`popup-overlay ${closing ? "closing" : ""}`} onClick={togglePopup}>
          <div className={`popup-imeg ${closing ? "closing" : ""}`}>
            <div className={`popup ${showPopup ? "open" : "close"}`}>
              <img src="/img/virt.png" alt="Virt" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
/*
      <div className="comparison-grid">
      <table>
        <tbody>
          {gridData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={`${rowIndex}-${cellIndex}`}>
                  <div className={`row-container row-${rowIndex}`}>{cell}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </div>*/
  const gridData = [
    ['', '', '', '', '', ''],
    ['', '', '', '', '', ''],
    ['', '', '', '', '', ''],
    ['', '', '', '', '', ''],
    ['', '', '', '', '', ''],
    ['', '', '', '', '', ''],
  ];
  //Companies
  gridData[0][1] = 'SodiumHosting';
  gridData[0][2] = 'BisectHosting';
  gridData[0][3] = 'Vultr';
  gridData[0][4] = 'Linode';
  gridData[0][5] = 'Kamatera';
  //Things?
  gridData[0][0] = 'Close to $10 Package';
  gridData[1][0] = 'Cpu cores';
  gridData[2][0] = 'Ram';
  gridData[3][0] = 'Storage';
  gridData[4][0] = 'Bandwidth';
  gridData[5][0] = 'Price';
  //vCpu cores?
  gridData[1][1] = '2 vCores';
  gridData[1][2] = '3 vCores';
  gridData[1][3] = '1 vCPU';
  gridData[1][4] = '1 CPU Core';
  gridData[1][5] = '2 vCPU';
  //Ram
  gridData[2][1] = '8 GB';
  gridData[2][2] = '2 GB';
  gridData[2][3] = '2 GB';
  gridData[2][4] = '2 GB';
  gridData[2][5] = '2 GB';
  //Storage
  gridData[3][1] = '80 GB';
  gridData[3][2] = '20 GB';
  gridData[3][3] = '55 GB';
  gridData[3][4] = '50 GB';
  gridData[3][5] = '30 GB';
  //Bandwidth
  gridData[4][1] = '1 TB';
  gridData[4][2] = '2 TB';
  gridData[4][3] = '2 TB';
  gridData[4][4] = '2 TB';
  gridData[4][5] = '5 TB';
  //Price
  gridData[5][1] = '$8.99/mo';
  gridData[5][2] = '$8.00/mo';
  gridData[5][3] = '$10.00/mo';
  gridData[5][4] = '$12.00/mo';
  gridData[5][5] = '$12.00/mo';