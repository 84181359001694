import React, { useState, useEffect, useRef }from "react";
import "./Home.css";
import 'bootstrap/dist/css/bootstrap.css';

export const Home = () => {
  const [words, setWords] = useState(['YouTube', 'Twitch', 'Community', 'Friends']);
  const [wordIndex, setWordIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setLetterIndex(letterIndex => {
        if (deleting) {
          if (letterIndex === 0) {
            setDeleting(false);
            setWordIndex(wordIndex => (wordIndex + 1) % words.length);
          }
          return letterIndex - 1;
        } else {
          return letterIndex + 1;
        }
      });
    }, 100);

    return () => clearInterval(interval);
  }, [deleting, letterIndex, words, wordIndex]);

  useEffect(() => {
    const timeout = deleting ? 700 : 1500;
    const timer = setTimeout(() => {
      setDeleting(true);
    }, timeout);

    return () => clearTimeout(timer);
  }, [deleting]);
  return (
    <div><div className="top d-flex flex-column justify-content-center align-items-center" style={{height: "80vh"}}>
    <div className="text" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <h1 style={{ color: "#ffffff", fontSize: "3.5rem",fontWeight: "bold" }}>Game Server Hosting</h1>
      <div className="typing-text" style={{marginBottom: "20px"}}>
        <h1 style={{ color: "#ffffff", fontSize: "3.5rem"  }}>for your <u style={{textDecorationColor: '#7CB9E8'}}><span>{words[wordIndex].substring(0, letterIndex)}</span></u></h1>
      </div>
      <a class="explore-button" href={"/services"}>Explore Our Services &gt;</a>
    </div>
  </div>
      <h1 style={{ color: "#ffffff" }}>Why choose us?</h1>
      <div className="d-flex flex-row justify-content-center flex-wrap">
          <div class="homecontainer">
            <div class="homebox">
              <img src="/img/computer.svg"/>
              <div class="text">
                <h3 style={{ fontWeight: "bold" }}>Powerful Hardware</h3>
                <r5>We use powerful hardware to give YOUR server the best performance.</r5>
              </div>
            </div>
          </div>
          <div class="homecontainer">
            <div class="homebox">
              <img src="/img/time.svg" />
              <div class="text">
                <h3 style={{ fontWeight: "bold" }}>99.9% Uptime</h3>
                <r5>We will make sure your server will always be online.</r5>
              </div>
            </div>
          </div>
          <div class="homecontainer">
            <div class="homebox">
              <img src="/img/monitoring.svg"/>
              <div class="text">
                <h3 style={{ fontWeight: "bold" }}>Public Status</h3>
                <r5>We have nothing to hide so we have our node statuses public.</r5>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center flex-wrap">
          <div class="homecontainer">
            <div class="homebox">
              <img src="/img/support.svg"/>
              <div class="text">
                <h3 style={{ fontWeight: "bold" }}>24/7 Support</h3>
                <r5>Our support team are online 24/7 to make sure you get the help you need.</r5>
              </div>
            </div>
          </div>
          <div class="homecontainer">
            <div class="homebox">
              <img src="/img/protection.svg"/>
              <div class="text">
                <h3 style={{ fontWeight: "bold" }}>DDOS Protection</h3>
                <r5>All of our servers are equiped with ddos protection to ensure your server is online.</r5>
              </div>
            </div>
          </div>
          <div class="homecontainer">
            <div class="homebox">
              <img src="/img/setup.svg"/>
              <div class="text">
                <h3 style={{ fontWeight: "bold" }}>Instant Setup</h3>
                <r5>Our servers are set up instantly after payment is received and processed by our systems</r5>
              </div>
            </div>
          </div>
      </div>
      <br></br>
      <br></br>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 style={{ color: "#ffffff" }}>Our Locations</h1>
        <br></br>
        <br></br>
        <div class="map-container">
          <img src="/img/map.webp" alt="Map"/>
          <div class="dot-wrapper dot-2"style={{ left: "49.3%", top: "33%" }}>
            <span class="dot"></span>
            <div class="dot-info" style={{left: "50%", top: "200%"}}>Germany</div>
          </div>
          <div class="dot-wrapper dot-2"style={{ left: "23%", top: "45%" }}>
            <span class="dot"></span>
            <div class="dot-info" style={{left: "50%", top: "200%"}}>USA, Atlanta</div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};